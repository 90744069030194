import { useEffect, useMemo, useState } from "react";
import type { NavFooterType, NavItemType } from "@wbly/data-storefront";
import {
  resetNav,
  setDesktop,
  setItemLevel,
  setNavItems,
  $nav,
} from "./nav.store";

function isElementOffScreen(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.bottom < 0 ||
    rect.top > window.innerHeight ||
    rect.right < 0 ||
    rect.left > window.innerWidth
  );
}

export const useNav = ({ menuItems, navFooter }) => {
  const memoItems: NavItemType[] = useMemo(() => menuItems, [menuItems]);
  const memoFooter: NavFooterType = useMemo(() => navFooter, [navFooter]);
  const isDesktop = $nav.get().isDesktop;
  const [panelOffset, setPanelOffset] = useState(100);

  useEffect(() => {
    resetNav();

    if (isDesktop) {
      setNavItems(memoItems);
      return;
    }

    setItemLevel({ level: 1, items: memoItems, type: "mobile" });
  }, [isDesktop, memoItems]);

  useEffect(() => {
    const banner = document.querySelector(".Banner") as HTMLElement;
    const header = document.querySelector(".NewHeader") as HTMLElement;
    const isBannerOffScreen = isElementOffScreen(banner);
    const headerHeight = header.offsetHeight;

    if (isBannerOffScreen) {
      setPanelOffset(headerHeight);
      return;
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1040) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }

    window.addEventListener("resize", () => {
      // TODO make a mini function
      if (window.innerWidth > 1040) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    });

    window.addEventListener("scroll", () => {
      // Probably the best solution until CSS fully supports
      // anchor positioning and without using a library.
      // https://developer.mozilla.org/en-US/docs/Web/CSS/position-anchor

      const banner = document.querySelector(".Banner") as HTMLElement;
      const header = document.querySelector(".NewHeader") as HTMLElement;
      const offSetLimit = banner.offsetHeight;
      const headerHeight = header.offsetHeight;
      const headerTop = header.offsetTop;

      // Set offset for panel not including the disappearing banner
      if (headerTop > offSetLimit) {
        setPanelOffset(headerHeight);
        return;
      }

      // Set offset for panel including the disappearing banner
      setPanelOffset(100);
    });

    return () => {
      window.removeEventListener("resize", () => {});
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return { memoItems, memoFooter, panelOffset };
};
